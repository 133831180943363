@import '../utility';

.tier__confirm {

  margin: 6rem;
  .confirm__title {
    color: $darkBlue;
    font-weight: 700;
    font-size: 2.25rem;
    margin-top: 3.5rem;
  }

  .content__wrapper {
    @extend .flex--space;
    column-gap: 1.5rem;

    .tier__confirm__info {
      @extend .flex--column;
      row-gap: 2rem;
      margin-top: 2rem;
    }
    .tier__current__info {
      @extend .flex-column !optional;
      margin-top: 1.5rem;
    }
  }

  .current__info__title,
  .confirm__subTitle {
    color: $darkBlue;
    font-weight: 600;
    font-size: 2rem;
  }
  .current__info__subContent {
    margin: 1.5rem 0;

    .current__subTitle {
      color: $neutral;
      font-weight: 700;
      font-size: 1.15rem;
    }
    span {
      display: block;
    }
  }

  @include respond(ipad) {
    .content__wrapper {
      flex-direction: column-reverse;
      .tier__current__info {
        width: 100%;
      }
    }
  }
}
